import React, { Component, useState } from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import {
  medWrapper,
  headlineThree,
  buttonOne,
  fontSizer,
  colors,
} from "../Utilities"
import JobLink from "./JobLink"
import Loading from "./models/Loading"

const JobsDisplaySection = styled.section`
  position: relative;
  overflow: visible;
  .wrapper {
    ${medWrapper};
  }

  .jobStyles {
    align-self: flex-start;
    width: calc(100%);

    @media (min-width: 768px) {
      position: sticky;
      top: 2rem;
      left: 2rem;
      width: calc(25% - 2rem);
      margin-right: 2rem;
    }

    button {
      ${headlineThree};
      ${fontSizer(1.8, 2.4, 76.8, 160, 1.4)}
      display: block;
      margin: 1rem 0;
      background: transparent;
      border: none;
      font-weight: 100;
      text-align: left;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      @media (min-width: 768px) {
      }
    }

    button.active {
      color: ${colors.colorTertiary};
    }
  }

  .jobDisplay {
    display: flex;
    flex-wrap: wrap;
    width: calc(100%);
    margin-top: 5rem;

    @media (min-width: 768px) {
      width: calc(100%);
      margin-top: 0;
    }
  }

  .jobsLoad {
    width: calc(100%);
    text-align: center;

    button {
      ${buttonOne};
    }
  }
`

class JobsDisplay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allJobs: [],
      display: 8,
      totalJobs: 0,
      moreCurrentJobs: false,
      current: [],
    }
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        ...prevState,
        allJobs: this.props.jobs,
        totalJobs: this.props.jobs.length,
        current: this.props.jobs.slice(0, this.state.display),
        moreCurrentJobs: this.props.jobs.length > this.state.display,
      }
    })
  }

  handleLoadMore = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        current: this.props.jobs.slice(
          0,
          this.state.current.length + this.state.display
        ),
        moreCurrentJobs:
          this.state.totalJobs > this.state.current.length + this.state.display,
      }
    })
  }

  render() {
    const styles = this.props.styles

    return (
      <JobsDisplaySection id="jobDisplaySection">
        <div className="wrapper">
          <div className="jobDisplay">
            {this.state.current.map(({ node: job }, index) => {
              const catName = styles.find(
                style => style.node.wordpress_id === job.reno_style[0]
              )
              return (
                <JobLink
                  key={job.id}
                  job={job}
                  catName={catName.node.name}
                  index={index}
                />
              )
            })}
          </div>

          <div className="jobsLoad">
            <button
              onClick={this.handleLoadMore}
              disabled={!this.state.moreCurrentJobs}
            >
              {this.state.moreCurrentJobs ? "Load More" : "No More"}
            </button>
          </div>
        </div>
        {this.state.loading && <Loading />}
      </JobsDisplaySection>
    )
  }
}

export default JobsDisplay
