import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { TimelineMax, CSSPlugin, AttrPlugin } from "gsap/all"

// import ScrollMagic from "scrollmagic"
// import animationGsap from "animation.gsap"

import { headlineThree, colors, bodyCopy } from "../Utilities"

import TextureCopper from "../elements/TextureCopper"
import TextureMarble from "../elements/TextureMarble"
import TextureWood from "../elements/TextureWood"
// animationGsap
// Need this for tree shaking.
const plugins = [CSSPlugin, AttrPlugin]

const JobLinkStyle = styled(Link)`
  align-self: center;
  position: relative;
  width: calc(100% - 1rem);
  margin: 0 0.5rem 7.5rem 0.5rem;

  @media (min-width: 768px) {
    width: calc(50% - 8rem);
    margin: 7.5rem 4rem 7.5rem 4rem;
  }

  .jobImage {
    position: relative;
    width: 100%;

    &__texture {
      position: absolute;
      top: -3rem;
      left: ${props => (props.posleft === 1 ? "3rem" : "-3rem")};
      width: 100%;
      height: 100%;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.46);
      z-index: -1;
    }
  }

  .jobTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    background: rgba(0, 0, 0, 0.45);
    opacity: 1;

    @media (min-width: 768px) {
      opacity: 0;
      background: rgba(0, 0, 0, 0.75);
    }

    &__headline {
      position: relative;
      margin: 0 2rem;

      h3 {
        ${bodyCopy};
        position: relative;
        color: ${colors.white};
        text-align: center;
        transition: all 0.3s ease 0.3s;
        transform-origin: center bottom;
        transform: translateY(2rem);
        opacity: 1;

        @media (min-width: 768px) {
          opacity: 0;
          transform: translateY(0rem);
        }
      }
      &::after {
        position: absolute;
        bottom: -1rem;
        left: 0;
        right: 0;
        height: 0.25rem;
        background: ${colors.white};
        transition: all 0.3s ease 0.15s;
        transform-origin: center center;
        transform: scale(1);
        content: "";
      }

      @media (min-width: 768px) {
        &::after {
          bottom: 0;
          transform: scale(0);
        }
      }
    }

    &__category {
      position: absolute;
      top: 2rem;
      left: 2rem;

      p {
        ${headlineThree};
        color: ${colors.white};
      }
    }
  }

  &:hover {
    .jobTitle {
      opacity: 1;
      &__headline {
        h3 {
          transform: translateY(0rem);
          opacity: 1;
        }
        &::after {
          transform: scale(1);
        }
      }
    }
  }
`

class JobLink extends Component {
  constructor(props) {
    super(props)
    this.jobRef = React.createRef()
    this.bgRef = React.createRef()
    this.imgRef = React.createRef()
  }
  state = { randNum: 0, randTop: 0, randLeft: 0 }

  componentDidMount() {
    const randNum = Math.floor(Math.random() * 3) + 1
    const randTop = Math.floor(Math.random() * 2) + 1
    const randLeft = Math.floor(Math.random() * 2) + 1
    this.setState({
      randNum,
      randTop,
      randLeft,
    })

    this.animateTexture()
  }

  componentWillUnmount() {
    this.state.timeLine.kill()
    this.state.controller.destroy()
    this.state.controller = null
    this.state.scene.destroy()
    this.state.scene = null
  }

  animateTexture = () => {
    if (typeof window !== "undefined") {
      import("scrollmagic").then(ScrollMagic => {
        import("animation.gsap").then(animationGsap => {
          const pluginsAgain = [animationGsap]
          let controller = new ScrollMagic.Controller()
          let timeLine = new TimelineMax()
          timeLine
            .add("start")
            .fromTo(this.bgRef.current, 1, { y: "-5%" }, { y: "5%" }, "start")
            .fromTo(this.imgRef.current, 1, { y: "5%" }, { y: "-5%" }, "start")
          let scene = new ScrollMagic.Scene({
            triggerElement: this.jobRef.current,
            duration: "150%",
            reverse: true,
            offset: 0,
            triggerHook: 1,
          })
            .setTween(timeLine)
            .addTo(controller)

          this.setState({ scene, controller, timeLine })
        })
      })
    }
  }

  render() {
    const { job, catName, index } = this.props

    const texture =
      this.state.randNum === 1 ? (
        <TextureCopper />
      ) : this.state.randNum === 2 ? (
        <TextureMarble />
      ) : (
        <TextureWood />
      )
    return (
      <JobLinkStyle
        ref={this.jobRef}
        to={`/portfolio/${job.slug}`}
        key={job.id}
        data-wpid={job.reno_style}
        postop={this.state.randTop}
        posleft={this.state.randLeft}
      >
        <div className="jobImage">
          <div ref={this.imgRef} className="jobImage__image">
            <Img
              fluid={
                job.acf._ikag_ouwo_intro_image.localFile.childImageSharp.fluid
              }
              alt={job.acf._ikag_ouwo_intro_image.alt_text}
            />
          </div>
          <div ref={this.bgRef} className="jobImage__texture">
            {texture}
          </div>
        </div>
        <div className="jobTitle">
          <div className="jobTitle__headline">
            <h3>{job.title}</h3>
          </div>
          <div className="jobTitle__category">
            <p>{catName}</p>
          </div>
        </div>
      </JobLinkStyle>
    )
  }
}

export default JobLink
