import React from "react"
import styled from "styled-components"
import Lottie from "react-lottie"
import * as success from "../../elements/lottieAnimations/ikag-hammer-load2.json"
import { colors } from "../../Utilities/index.js"

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: success.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const LoadingModel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.colorSecondary};
  z-index: 1000;
`

const Loading = () => {
  return (
    <LoadingModel>
      <div>
        <div>
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </div>
    </LoadingModel>
  )
}

export default Loading
