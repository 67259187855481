import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroImage from "../components/HeroImage"
import Intro from "../components/Intro"
import JobsDisplay from "../components/JobsDisplay"
import CalloutSection from "../components/CalloutSection"

const portfolio = props => {
  const location = props.location.pathname
  const { acf } = props.data.page
  const {
    jobs: { edges: jobs },
    styles: { edges: styles },
  } = props.data

  const metaTitle = acf._swb_theme_meta_title
  const metaDescription = acf._swb_theme_description
  const metaImage = acf._swb_theme_image.localFile.publicURL

  const heroImgFluid = acf._ikag_hero_image.localFile.childImageSharp.fluid
  const introTitle = acf._ikag_intro_title
  const introItalics = acf._ikag_intro_title_italic
  const introContent = acf._ikag_intro_content

  const cosTitleTop = acf._ikag_cos_title_top
  const cosTitleBot = acf._ikag_cos_title_italic
  const cosBtnText = acf._ikag_cos_button_text
  const cosBtnLink = acf._ikag_cos_button_link.post_name

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage fluid={heroImgFluid} />
      <Intro title={introTitle} italic={introItalics} content={introContent} />
      <JobsDisplay jobs={jobs} styles={styles} />
      <CalloutSection
        titleTop={cosTitleTop}
        titleBot={cosTitleBot}
        btnText={cosBtnText}
        btnLink={cosBtnLink}
      />
    </Layout>
  )
}

export const homeQuery = graphql`
  query portfolioPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
        _ikag_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_intro_title
        _ikag_intro_title_italic
        _ikag_intro_content

        _ikag_cos_title_top
        _ikag_cos_title_italic
        _ikag_cos_button_text
        _ikag_cos_button_link {
          post_name
        }
      }
    }

    jobs: allWordpressWpOurWork {
      edges {
        node {
          title
          id
          slug
          reno_style
          acf {
            _ikag_ouwo_intro_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }

    styles: allWordpressWpRenoStyle {
      edges {
        node {
          id
          name
          slug
          wordpress_id
        }
      }
    }
  }
`

export default portfolio
